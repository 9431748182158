import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "main" }
const _hoisted_2 = {
  id: "app-motion",
  ref: "motionEl"
}
const _hoisted_3 = { class: "app-inner" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_header = _resolveComponent("app-header")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_app_header),
    _createElementVNode("main", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, null, 512),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_view)
      ])
    ])
  ]))
}