
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  Ref,
  ref,
  watch,
} from 'vue';
import { gsap } from 'gsap';
import debounce from 'lodash.debounce';
import SplitType from 'split-type';
import { TouchRipple } from 'vue-touch-ripple';

import InlineSvg from 'vue-inline-svg';
import CardTag from '@/components/ui/CardTag.vue';
import { store } from '@/store';
import { useRoute, onBeforeRouteLeave } from 'vue-router';
import { TarotMotion } from '@/modules/Tarot';

export default defineComponent({
  name: 'CardView',
  components: { CardTag, InlineSvg, TouchRipple },
  setup() {
    const route = useRoute();

    const card = computed(() => store.getCards(String(route.params?.id)));

    const title: Ref<HTMLElement | null> = ref(null);
    const subtitle: Ref<HTMLElement | null> = ref(null);
    const tags: Ref<HTMLElement | null> = ref(null);
    const description: Ref<HTMLElement | null> = ref(null);
    const mainBtn: Ref<HTMLElement | null> = ref(null);
    const isLoading = computed(() => store.isLoading);

    const debouncedOnResizeWindow = debounce(onResizeWindow, 200);

    const subtitleSplitType: Array<any> = [];
    let timeline = gsap.timeline();

    watch(() => isLoading.value, (value) => {
      if (!value) {
        setAnimation();
      }
    });

    watch(() => card.value, (value) => {
      if (value?.id) {
        new Promise<void>((resolve) => {
          setTimeout(() => {
            if (title.value) {
              new SplitType(title.value, { types: 'words' });

              gsap.set(title.value, { opacity: 1 });
            }

            if (description.value) {
              const children = description.value?.children as HTMLCollection;

              for (let i = 0; i < children.length; i += 1) {
                const item = children[i] as HTMLElement;

                subtitleSplitType.push(new SplitType(
                  item,
                  { types: 'lines', lineClass: 'card-line' },
                ));
              }
            }

            gsap.set(description.value, { opacity: 1 });

            resolve();
          }, 100);
        }).then(() => {
          if (!isLoading.value) {
            setAnimation();
          }
        });
      }
    }, { immediate: true });

    function onResizeWindow() {
      for (let i = 0; i < subtitleSplitType.length; i += 1) {
        subtitleSplitType[i]?.split();
      }

      const element = description.value;
      const lines = element ? element.querySelectorAll('.card-line') : [];

      gsap.set(lines, { opacity: 1, y: 0 });
    }

    function setAnimation() {
      timeline?.kill();

      const element = description.value;
      const lines = element ? element.querySelectorAll('.card-line') : [];
      const tagItems = tags.value?.children as HTMLCollection;
      const titleItems = title.value?.children as HTMLCollection;

      timeline = gsap.timeline({
        delay: 0.3,
      });

      timeline
        .to(subtitle.value, { opacity: 1, y: 0, ease: 'sine.out' })
        .to(titleItems, {
          opacity: 1, y: 0, stagger: 0.2, ease: 'sine.out', willChange: 'transform, opacity',
        }, '<')
        .to(tagItems, {
          opacity: 1, stagger: 0.1, ease: 'sine.out', delay: 0.2,
        }, '<')
        .to(lines, {
          opacity: 1, y: 0, stagger: 0.07, ease: 'sine.out',
        }, '<')
        .to(mainBtn.value, { opacity: 1, ease: 'sine.out' }, '<');
    }

    const beforeLeave = () => new Promise((resolve) => {
      timeline.timeScale(2).reverse().then(() => resolve({}));
    });

    onBeforeRouteLeave((_, __, next) => {
      beforeLeave().then(() => next());
      TarotMotion.cards.backToChoice();
    });

    onMounted(() => {
      window.addEventListener('resize', debouncedOnResizeWindow);
    });

    onBeforeUnmount(() => {
      timeline?.kill();

      window.removeEventListener('resize', debouncedOnResizeWindow);
    });

    return {
      card,
      title,
      subtitle,
      description,
      tags,
      mainBtn,
      readMore: async () => {
        await beforeLeave();
        store.setView('divination', 'card');
      },
    };
  },
});
