
import { defineComponent } from 'vue';
import InlineSvg from 'vue-inline-svg';
import { TouchRipple } from 'vue-touch-ripple';

export default defineComponent({
  name: 'SocialIcon',
  components: { TouchRipple, InlineSvg },
  props: {
    icon: {
      type: String,
      default: '',
    },
    href: {
      type: String,
      default: '',
    },
    network: {
      type: String,
      default: 'vk',
    },
    title: {
      type: String,
      default: '',
    },
  },
});
