
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  Ref,
  ref,
  watch,
} from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import type { Swiper as SwiperInterface } from 'swiper';
import { EffectFade } from 'swiper';
import { gsap } from 'gsap';
import debounce from 'lodash.debounce';

import BtnWhite from '@/components/ui/BtnWhite.vue';
import SocialIcon from '@/components/ui/SocialIcon.vue';
import DivinationTabs from '@/components/ui/DivinationTabs.vue';
import InlineSvg from 'vue-inline-svg';
import { onBeforeRouteLeave, useRoute } from 'vue-router';
import { store } from '@/store';
import { TarotMotion } from '@/modules/Tarot';

export default defineComponent({
  name: 'DivinationView',
  components: {
    DivinationTabs,
    Swiper,
    SwiperSlide,
    BtnWhite,
    SocialIcon,
    InlineSvg,
  },
  setup() {
    const route = useRoute();

    const card = computed(() => store.getCards(String(route.params?.id)));
    const isShare = computed(() => !!route.query?.share);
    const shareUrl = computed(() => new URL(`${window.location.href}?share=true`));
    const activeIndex = ref(0);
    const heroPaddingTop = ref('unset');
    const tabs: Ref<HTMLElement | null> = ref(null);
    const slider: Ref<SwiperInterface | null> = ref(null);
    const spread: Ref<HTMLElement | null> = ref(null);
    const link: Ref<HTMLElement | null> = ref(null);
    const btnNext = ref(null);
    const isLastSlide = ref(false);
    const isLoading = computed(() => store.isLoading);

    const debounceOnResize = debounce(onResizeTabs, 200);

    const socialLinks = [
      {
        icon: 'vk',
        network: 'vk',
      },
      {
        icon: 'ok',
        network: 'odnoklassniki',
      },
      {
        icon: 'tg',
        network: 'telegram',
      },
    ];
    const divinationTabs = [
      {
        icon: 'heart',
        text: 'В отношениях',
      },
      {
        icon: 'heart',
        text: 'В работе',
      },
      {
        icon: 'heart',
        text: 'В финансах',
      },
    ];
    let timeline = gsap.timeline();

    watch(() => isLoading.value, (value) => {
      if (!value) {
        setAnimation();
      }
    });

    function changeSlide(index: number) {
      slider.value?.slideTo(index, 300);
    }

    function onSlideChange(swiper: SwiperInterface) {
      const { activeIndex: active, slides } = swiper;
      const tabsChildren: HTMLCollection | undefined = tabs.value?.children;

      if (!tabsChildren) return;

      const element = tabsChildren[active] as HTMLElement;

      gsap.to(tabs.value, { x: -element.offsetLeft, ease: 'sine.inOut' });

      activeIndex.value = active;

      isLastSlide.value = (active + 1) === slides.length;
    }

    function onSwiper(swiper: SwiperInterface) {
      slider.value = swiper;
    }

    function slideNext() {
      slider.value?.slideNext();
    }

    function setPaddingTop() {
      const marginTop = '3rem';
      const { height } = tabs.value?.getClientRects()[0] as DOMRect;

      heroPaddingTop.value = `calc(${height}px + ${marginTop})`;
    }

    function setAnimation() {
      timeline?.kill();

      const firstSlide = slider.value?.slides[0].children as HTMLCollection;
      const tabsChildren = tabs.value?.querySelectorAll('.divination-tab') as HTMLCollection | undefined;
      const spreadChildren = spread.value?.children as HTMLCollection;
      const linkChildren = link.value?.children as HTMLCollection;

      if (!firstSlide || !tabsChildren || !spreadChildren || !linkChildren) return;

      timeline = gsap.timeline({ delay: 0.3 });

      timeline
        .to(tabsChildren, { opacity: 1, ease: 'sine.inOut', stagger: 0.2 })
        .to(firstSlide, {
          opacity: 1, ease: 'sine.inOut', stagger: 0.2, delay: 0.1,
        }, '<')
        .to(btnNext.value, { opacity: 1, ease: 'sine.inOut', delay: 0.2 }, '<')
        .to(spreadChildren, {
          opacity: 1, ease: 'sine.inOut', delay: 0.3, stagger: 0.2,
        }, '<')
        .to(linkChildren, {
          opacity: 1, ease: 'sine.inOut', delay: 0.4, stagger: 0.2,
        }, '<');
    }

    function onResizeTabs() {
      if (!tabs.value) return;

      const tabsChildren = tabs.value.children;
      const element = tabsChildren[activeIndex.value] as HTMLElement;

      if (element) {
        gsap.to(tabs.value, { x: -element.offsetLeft });
      }
    }

    const beforeLeave = () => new Promise((resolve) => {
      timeline.timeScale(2).reverse().then(() => resolve({}));
    });

    onBeforeRouteLeave((_, __, next) => {
      beforeLeave().then(() => next());
      TarotMotion.cards.backToChoice();
    });

    onMounted(() => {
      window.addEventListener('resize', debounceOnResize);

      setPaddingTop();

      if (!isLoading.value) {
        setAnimation();
      }
    });

    onBeforeUnmount(() => {
      timeline?.kill();

      window.removeEventListener('resize', debounceOnResize);
    });

    const close = async () => {
      beforeLeave().then(() => {
        store.setView('card', 'card');
      });
    };

    return {
      socialLinks,
      divinationTabs,
      isLastSlide,
      spread,
      link,
      btnNext,
      tabs,
      activeIndex,
      heroPaddingTop,
      card,
      isShare,
      shareUrl,
      EffectFade,
      beforeLeave,
      close,

      onSlideChange,
      onSwiper,
      slideNext,
      changeSlide,
    };
  },
});
