
import { defineComponent } from 'vue';
import InlineSvg from 'vue-inline-svg';
import { TouchRipple } from 'vue-touch-ripple';

export default defineComponent({
  name: 'BtnWhite',
  components: { TouchRipple, InlineSvg },
  props: {
    isShare: {
      type: Boolean,
      default: false,
    },
  },
});
