import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "header",
  class: "header"
}
const _hoisted_2 = { class: "header__wrapper" }
const _hoisted_3 = { class: "header__logo" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, { to: "/" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_inline_svg, {
              class: _normalizeClass(["svg-logo", { 'active': _ctx.isSvgAnimation }]),
              src: require('@/assets/svg/logo.svg'),
              name: "logo"
            }, null, 8, ["class", "src"])
          ])
        ]),
        _: 1
      })
    ])
  ]))
}