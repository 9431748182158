
import { computed, defineComponent } from 'vue';
import InlineSvg from 'vue-inline-svg';
import { store } from '@/store';

export default defineComponent({
  name: 'AppHeader',
  components: { InlineSvg },
  setup() {
    const isSvgAnimation = computed(() => !store.isLoading);

    return {
      isSvgAnimation,
    };
  },
});
