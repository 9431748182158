
import {
  computed,
  defineComponent,
  watch,
  onBeforeUnmount,
  onMounted,
  Ref,
  ref,
} from 'vue';
import { gsap } from 'gsap';
import SplitType from 'split-type';
import debounce from 'lodash.debounce';
import { store } from '@/store';

import InlineSvg from 'vue-inline-svg';
import { TouchRipple } from 'vue-touch-ripple';

export default defineComponent({
  name: 'HomeView',
  components: { InlineSvg, TouchRipple },
  setup() {
    const firstElements: Ref<Array<HTMLElement>> = ref([]);
    const secondElements: Ref<Array<HTMLElement>> = ref([]);
    const subtitleElement: Ref<HTMLElement | null> = ref(null);
    const mainBtn: Ref<HTMLElement | null> = ref(null);
    const svgAnimation = ref({
      star1: false,
      star2: false,
      star3: false,
    });
    const isLoading = computed(() => store.isLoading);

    const debouncedOnResizeWindow = debounce(onResizeWindow, 200);

    let subtitleSplitType: any = null;
    let timeline = gsap.timeline();

    watch(() => isLoading.value, (value) => {
      if (!value) {
        setAnimation();
      }
    });

    function setElementsRef(el: HTMLElement, isFirst = false) {
      if (isFirst) {
        firstElements.value.push(el);
      } else {
        secondElements.value.push(el);
      }
    }

    function lineAnimation() {
      const element = subtitleElement.value;
      const lines = element ? element.querySelectorAll('.line') : [];

      for (let i = 0; i < lines.length; i += 1) {
        const delay = i / lines.length;

        gsap.to(lines[i].children, {
          opacity: 1, y: 0, stagger: 0.005, delay, ease: 'sine.out',
        });
      }
    }

    function setAnimation() {
      timeline?.kill();

      timeline = gsap.timeline({
        delay: 0.3,
        onStart() {
          lineAnimation();
        },
        onUpdate() {
          const timelineProgress = this.progress() * 10;

          if (timelineProgress >= 1.5) {
            svgAnimation.value.star1 = true;
          }

          if (timelineProgress >= 2.5) {
            svgAnimation.value.star2 = true;
          }

          if (timelineProgress >= 3.5) {
            svgAnimation.value.star3 = true;
          }
        },
      });

      timeline
        .to(firstElements.value, {
          opacity: 1, y: 0, ease: 'sine.out', stagger: 0.05,
        })
        .to(secondElements.value, {
          opacity: 1, y: 0, ease: 'sine.out', stagger: 0.05,
        }, '>-0.7')
        .to(mainBtn.value, { opacity: 1, ease: 'sine.out' });
    }

    function onResizeWindow() {
      if (isLoading.value) {
        return;
      }

      subtitleSplitType?.split();

      const element = subtitleElement.value;
      const words = element ? element.querySelectorAll('.word') : [];

      gsap.set(words, { opacity: 1, y: 0 });
    }

    function beforeLeave() {
      return new Promise((res) => {
        timeline?.kill();

        const element = subtitleElement.value;
        const lines = element ? element.querySelectorAll('.line') : [];
        const stars = document.querySelectorAll('.home__star');

        timeline = gsap.timeline({
          onComplete() {
            res({});
          },
        }).timeScale(1.5);

        timeline
          .to(stars, { opacity: 0 })
          .to(firstElements.value, {
            opacity: 0, y: -20, delay: 0.05, willChange: 'transform, opacity',
          }, '<')
          .to(secondElements.value, {
            opacity: 0, y: -20, delay: 0.1, willChange: 'transform, opacity',
          }, '<')
          .to(lines, {
            opacity: 0, y: -20, stagger: 0.1, delay: 0.15, willChange: 'transform, opacity',
          }, '<')
          .to(mainBtn.value, { opacity: 0, delay: 0.2 }, '<');
      });
    }

    onMounted(() => {
      window.addEventListener('resize', debouncedOnResizeWindow);

      new Promise<void>((resolve) => {
        setTimeout(() => {
          if (subtitleElement.value) {
            subtitleSplitType = new SplitType(
              subtitleElement.value,
              { types: 'words, lines' },
            );
          }

          gsap.set(subtitleElement.value, { opacity: 1 });

          resolve();
        }, 300);
      }).then(() => {
        if (!isLoading.value) {
          setAnimation();
        }
      });
    });

    onBeforeUnmount(() => {
      timeline?.kill();

      window.removeEventListener('resize', debouncedOnResizeWindow);
    });

    return {
      svgAnimation,
      subtitleElement,
      mainBtn,
      store,
      beforeLeave,

      setElementsRef,
    };
  },
});
