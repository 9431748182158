import { createApp } from 'vue';
import VueSocialSharing from 'vue-social-sharing';

import App from '@/App.vue';
import router from '@/router';
import i18n from '@/i18n';

import '@/style/index.scss';

import WheelSwipe from 'wheel-swipe';

new WheelSwipe({ cancelScroll: false });

createApp(App)
  .use(VueSocialSharing)
  .use(i18n)
  .use(router)
  .mount('#app');
